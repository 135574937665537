(function($,w,s){$(function(){

    /**********************************************************************************
        Bloom - GDPR Overlay Plugin - Settings Panel Options
    **********************************************************************************/

    class bgopSettings
    {

        setProperties()
        {

            this.button;

            this.bloom = $( 'p.et_bloom_popup_input' ).find( 'input' );

            this.divi  = $( 'div.et_pb_newsletter_form' ).find( 'input' );

        } // end setProperties

        constructor()
        {

            this.php = bgopPHP;

            if ( ! this.isSet() )
            {

                this.setProperties();

                this.bindEvents();

                // this.initialize();

            }
        } // end constructor

        initialize()
        {

            // Nothing to do here

        } // end initialize

        isSet()
        {

            return ( null !== s.getItem( this.php.pre + 'Session' ) );

        } // end get

        set()
        {

            return s.setItem( this.php.pre + 'Session', true );

        } // end set

        addOverlay( input, form )
        {

            let sel = 'Bloom' == form ? 'div.et_bloom_form_container' : 'div.et_pb_newsletter';

            return input
                    .closest( sel )
                    .css( 'position', 'relative' )
                    .append( $( this['setButton' + form]( input ) ) );

        } // end addOverlay

        setButtonBloom( input )
        {

            this.button = input.parent().siblings( 'button' ).attr( 'disabled', true );

            return this.setColor( input, 'div.et_bloom_form_content' );

        }// end setColor

        setButtonDivi( input )
        {

            // Just to be sure
            $( '#et_pb_signup_email' ).empty();

            this.button = input.parent()
                            .closest( 'div.et_pb_newsletter_form' )
                            .find( 'a.et_pb_newsletter_button' )
                            .attr( 'disabled', true );

            return this.setColor( input, 'div.et_pb_newsletter' );

        }// end setColor

        setColor( input, sel )
        {

            // this.button = input.parent().siblings( 'button' ).attr( 'disabled', true );

            let dark    = 'rgb(80, 80, 80)',
                white   = 'rgb(255, 255, 255)',
                bgCol   = '' != this.php.bgCol ? '#' + this.php.bgCol.replace( '#', '' ) : input.closest( sel ).css( 'backgroundColor' ),
                link    = '' != this.php.link  ? '#' + this.php.link.replace( '#', '' )  : this.button.css( 'backgroundColor' ),
                color   = '' != this.php.color ? '#' + this.php.color.replace( '#', '' ) : this.button.css( 'color' );

                if ( white == color )
                {

                    // Fix the light grey background and white font problem by default layouts
                    color = white == bgCol || 'rgb(237, 237, 237)' == bgCol ? dark : color;

                } // end if

                if ( 'undefined' == typeof link || 'rgba(0, 0, 0, 0)' == link )
                    link = dark;

            return this.getHTML( bgCol, color, link );

        }// end setColor

        getHTML( bgCol, color, link )
        {

            return `<div id="bgop-overlay" class="bgop-overlay" style="background-color:${bgCol};">
                        <div id="bgop-close" class="bgop-close" style="color:${color};"></div>
                        <div class="bgop-wrap">
                            <div class="bgop-container">
                                <p class="bgop-paragraph" style="color:${color};">
                                    <span>${this.php.linkBefore}
                                        <a href="${this.php.linkHref}" target="_blank" style="color:${link};">${this.php.linkName}</a>
                                        ${this.php.linkAfter}
                                    </span>
                                </p>
                                <p class="bgop-paragraph" style="color:${color};">
                                    <input id="bgop-checkbox" class="bgop-checkbox" type="checkbox">
                                    <label for="bgop-checkbox" style="color:${color};"><span>${this.php.agree}</span></label>
                                </p>
                            </div>
                        </div>
                    </div>`.trim();

        } // end getHTML

        remove( el, time = 500 )
        {

            this.button.attr( 'disabled', false );

            el.fadeOut( time, function()
            {

                $(this).remove();

            });
        } // end remove

        routeForm( input, form )
        {

            if ( this.isSet() )
                return;

            let $sel = '#bgop-overlay';

            if ( $( $sel ).length > 0 )
                this.remove( $( $sel ), 0 );

            setTimeout( () =>
            {

                this.addOverlay( input.blur(), form );

            }, 10 );

            setTimeout( () =>
            {

                let $container = $( $sel ).addClass( 'show' );

                setTimeout( () =>
                {

                    $container.find( 'p.bgop-paragraph' ).addClass( 'show' );

                    $( '#bgop-checkbox' ).on( 'change', () =>
                    {

                        this.set();

                        this.remove( $container );

                    });

                    $( '#bgop-close' ).on( 'mousedown', () =>
                    {

                        this.remove( $container );

                    });
                }, 200 );
            }, 10 );

        } // end routeForm

        bindEvents( self = this )
        {

            self.bloom.on( 'focus', function()
            {

                self.routeForm( $(this), 'Bloom' );

            });

            self.divi.on( 'focus', function()
            {

                self.routeForm( $(this), 'Divi' );

            });

            if ( 'true' === self.php.noCok )
            {

                $(document).ajaxSuccess(function( e, a, b, c) {

                    if ( ( true === c.success && -1 !== b.data.indexOf( 'et_pb_submit_subscribe_form' ) ) || ( "success" == c.success && -1 !== b.data.indexOf( 'bloom_subscribe' ) ) )
                        sessionStorage.removeItem( 'bgopSession' );

                });
            }
        } // end bindEvents
    } // end class bgopSettings

    new bgopSettings;

});}(jQuery,window,sessionStorage));
